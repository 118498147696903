import * as React from "react";

import Layout from "../components/layout";
import "./all.scss";

const helpTips = [
  "Only upload PDFs, JPGs, Word, or Excel documents. I can't open any other file types.",
  "When scanning pages to a PDF - scan multiple pages - don't make 1 PDF per page of data.",
  "Don't do 2-sided scan unless the document is truly 2-sided. Eliminate blank pages.",
  "I already know what your documents are - you needn't label them 'W-2' or 'Mortgage interest Stmt'. Naming 'Scan 1' and 'Scan 2' is sufficient.",
  "If you are uploading multiple PDFs, please know that the first thing I do is merge them to one document, so don't spend unnecessary time with naming the PDF.",
  "If you upload a picture (JPG), please make sure it is focused, does not have shadowing, and has minimal borders from the surface beneath.",
  "I automatically get an email letting me know that you have uploaded documents - you needn't also send me an email of your own.",
];

const PortalHelpPage = () => (
  <Layout>
    <div className="container">
      <div className="columns">
        <div className="column has-text-centered">
          <h4 className="title is-4">{"Tips to successfully upload documents to the portal"}</h4>
        </div>
      </div>
    </div>
    <br />
    <div className="container">
      <div className="columns">
        <div className="column">
          <p className="content">
            <div style={{ margin: "0 40px" }}>
              <ul>
                {helpTips.map((o, i) => (
                  <li key={i}>{o}</li>
                ))}
              </ul>
            </div>
          </p>
        </div>
      </div>
    </div>
  </Layout>
);

export default PortalHelpPage;
